import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Card, Form, Button, Input, InputNumber, Row, Col, Select, Space, Steps,
  DatePicker, Checkbox, Descriptions, Radio, Image, notification, Tooltip, Modal,
} from 'antd';
import { SaveOutlined, CloseOutlined, PrinterFilled } from '@ant-design/icons';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import omit from 'lodash.omit';
import routes from '../../utils/routes';
import collections from '../../utils/collections';
import {
  addDoc, updateDoc, getDoc, getDocs, queryWhere, where, auth,
} from '../../utils/firebase';
import { GlobalContext } from '../../contexts/global';
import { ORDER_IS, ORDER_STATUS, ORDER_PRINT_TYPES } from '../../utils/options';
import { formatK, convertDayDate, nowDay } from '../../utils/common';
import Page404 from '../Page404';

const { TextArea } = Input;
const REQUIRED_MESSAGE = 'Bu alan zorunludur.';
const numberConf = {
  controls: false,
  min: 0,
  decimalSeparator: ',',
  precision: 0,
  style: {
    width: '100%',
  },
};
const now = nowDay();
const dateConf = {
  minDate: now,
  format: 'DD/MM/YYYY',
  showNow: false,
};

export default function OrdersCreate() {
  const { orderId } = useParams();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [required, setRequired] = useState([{
    required: true,
    message: REQUIRED_MESSAGE,
  }]);
  const navigate = useNavigate();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [searchParams] = useSearchParams();
  const {
    getPaper, getUserFullName, stockPaperFilter, syncStockPaper,
    USERS, isAdmin, isTemsilci, isUretim, isGrafik,
  } = useContext(GlobalContext);
  const [offer, setOffer] = useState(null);
  const [statusCurrent, setStatusCurrent] = useState(0);
  const [orderDetail, setOrderDetail] = useState(null);
  const printType = Form.useWatch('print_type', form);
  const stockMaterialCode = Form.useWatch('stock_material_code', form);
  const stockEn = Form.useWatch('stock_en', form);
  const bicakVorey = Form.useWatch('vorey_bicak', form);
  const bicakYaprak = Form.useWatch('yaprak_bicak', form);
  const bicakTahta = Form.useWatch('tahta_bicak', form);
  const bondingType = Form.useWatch('bonding_type', form);
  const offerNotes = Form.useWatch('offer_notes', form);
  const [padActive, setPadActive] = useState(false);
  const [psdActive, setPsdActive] = useState(false);
  const [stockDetail, setStockDetail] = useState({});
  const [showStockUpdate, setShowStockUpdate] = useState(false);
  const [showOfferLakUpdate, setShowOfferLakUpdate] = useState(false);
  const [showOfferSelofanUpdate, setShowOfferSelofanUpdate] = useState(false);
  const canChangeStock = useMemo(
    () => !orderId || !orderDetail?.stock_consumed_size
      ?.flatMap((s) => Object.values(s))
      .some((n) => n > 0),
    [orderId, orderDetail],
  );
  const isBicakRequired = useMemo(
    () => ((orderId && offer) && !(bicakVorey === 'Var' || bicakYaprak === 'Var' || bicakTahta === 'Var')),
    [bicakVorey, bicakYaprak, bicakTahta, orderId, offer],
  );
  const isPrintTypeFlekso = useMemo(() => printType === 'Flekso', [printType]);
  const isBondingTypeRequired = useMemo(() => bondingType === 'Makine', [bondingType]);

  useEffect(() => {
    form.validateFields();
  }, [isBondingTypeRequired]);

  useEffect(() => {
    setPadActive(statusCurrent > 0);
    setPsdActive(statusCurrent > 1);
  }, [statusCurrent]);

  useEffect(() => {
    form.validateFields(['print_approval_date', 'production_start_date']);
  }, [padActive, psdActive, form]);

  useEffect(() => {
    setPadActive(statusCurrent > 0);
    setPsdActive(statusCurrent > 1);
  }, [statusCurrent]);

  useEffect(() => {
    const offerId = searchParams.get('id');

    if (offerId && auth) {
      checkIfOrderExist(offerId);
    }
  }, [searchParams, auth, isAdmin]);

  async function loadOffer(id) {
    setLoading(true);

    await getDoc(collections.OFFERS, id)
      .then((data) => {
        if (!(isAdmin || isGrafik) && data.created_by !== auth.currentUser.uid) {
          return false;
        }

        setOffer(data);

        const others = [];

        if (data.yaldiz_type && data.yaldiz_type.length && data.yaldiz_type !== '--') others.push(`${data.yaldiz_type} Yaldız`);
        if (data.emboss === 'Var') others.push('Emboss');

        form.setFieldsValue({
          lak: data.lak,
          selofan: data.selofan,
          others,
          offer_notes: data.notes,
        });
      }).finally(() => {
        setLoading(false);
      });
  }

  async function checkIfOrderExist(offerId) {
    const query = queryWhere(collections.ORDERS, where('offer.id', '==', offerId));

    await getDocs(query).then((data) => {
      if (data[0]?.id) {
        navigate({
          pathname: routes.ORDER_DETAIL.replace(':orderId', data[0].id),
        }, {
          replace: true,
        });
      } else {
        loadOffer(offerId);
      }
    });
  }

  async function getOrderDetail() {
    setLoading(true);

    await getDoc(collections.ORDERS, orderId)
      .then((data) => {
        if (!(isAdmin || isGrafik) && data.created_by !== auth.currentUser.uid) {
          return false;
        }

        setOrderDetail(data);
        setOffer(data.offer);
      }).finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (orderId && !orderDetail) {
      getOrderDetail();
    }
  }, [orderId, orderDetail, isAdmin]);

  useEffect(() => {
    if (orderDetail) {
      const dateKeys = ['order_date', 'print_approval_date', 'production_start_date', 'shipment_date'];
      const nonFormKeys = ['created_at', 'created_by', 'id', 'status', 'offer', ...dateKeys];

      form.setFieldsValue(omit(orderDetail, nonFormKeys));
      setStatusCurrent(orderDetail.status);

      dateKeys.forEach((key) => {
        if (orderDetail[key]) {
          form.setFieldValue(key, convertDayDate(orderDetail[key]));
        }
      });
    }
  }, [orderDetail]);

  useEffect(() => {
    if (offer) {
      Object.values(stockPaperFilter).forEach((p) => {
        const mt = p[offer.material];
        if (mt) setStockDetail(mt);
      });
    }
  }, [offer, stockPaperFilter]);

  const stockMaterialCodes = useMemo(() => Object.keys(stockDetail).map((label) => ({
    label,
    value: label,
  })), [stockDetail]);

  const stockMaterialEn = useMemo(() => Object
    .keys(stockDetail[stockMaterialCode] || {}).map((label) => ({
      label,
      value: label,
    })), [stockMaterialCode]);

  function setBicak(type) {
    const types = ['vorey', 'tahta', 'yaprak'];
    const fields = {};

    types.filter((t) => t !== type).forEach((t) => {
      fields[`${t}_bicak`] = 'Yok';
    });

    form.setFieldsValue(fields);
  }

  const sections = [[{
    label: 'Firma Ticaret Ünvanı',
    children: offer?.client_name,
  }, {
    label: 'İşin Adı',
    children: offer?.name,
  }, {
    label: 'Satış Temsilcisi',
    children: getUserFullName(offer?.client_representative),
  }, {
    label: 'Sipariş Tarihi',
    children: (
      <Form.Item name="order_date" rules={required}>
        <DatePicker placeholder="Tarih Seçiniz" {...dateConf} />
      </Form.Item>
    ),
  }, {
    label: 'Baskı Onay Tarihi',
    children: (
      <Tooltip title="Baskı Onay Tarihi'ni sadece grafik ve admin ekibi belirleyebilir.">
        <Form.Item name="print_approval_date" rules={[{ ...required[0], required: padActive }]}>
          <DatePicker
            placeholder="Tarih Seçiniz"
            {...dateConf}
            disabledDate={() => !(isAdmin || isGrafik)}
            allowClear={(isAdmin || isGrafik)}
          />
        </Form.Item>
      </Tooltip>
    ),
  }, {
    label: 'Üretim Baslangıç Tarihi',
    children: (
      <Form.Item name="production_start_date" rules={[{ ...required[0], required: psdActive }]}>
        <DatePicker placeholder="Tarih Seçiniz" {...dateConf} />
      </Form.Item>
    ),
  }], [{
    label: ORDER_PRINT_TYPES[0].label,
    children: (<Radio value={ORDER_PRINT_TYPES[0].value} />),
  }, {
    label: ORDER_PRINT_TYPES[1].label,
    children: (<Radio value={ORDER_PRINT_TYPES[1].value} />),
  }, {
    label: ORDER_PRINT_TYPES[2].label,
    children: (<Radio value={ORDER_PRINT_TYPES[2].value} />),
  }, {
    label: ORDER_PRINT_TYPES[3].label,
    children: (<Radio value={ORDER_PRINT_TYPES[3].value} />),
  }, {
    label: ORDER_PRINT_TYPES[4].label,
    children: (<Radio value={ORDER_PRINT_TYPES[4].value} />),
  }, {
    label: 'İş',
    children: (
      <Form.Item name="order_type" rules={required}>
        <Select placeholder="Seçiniz" options={ORDER_IS} />
      </Form.Item>
    ),
  }], [], [{
    label: 'Kağıt Türü',
    span: 2,
    children: getPaper(offer?.material)?.name,
  }, {
    label: 'Etiket Adedi',
    span: 2,
    children: (
      <Form.Item name="total_piece">
        <Input />
        {/* // {...numberConf}
          // formatter={(value, info) => {
          //   if (!info.userTyping) {
          //     let val = info.input === 'undefined' || info.input === '' ? value : info.input;
          //     val = val.replaceAll(',', '').replaceAll('.', '');
          //     const f = formatK(Number.parseInt(val, 10), 0);
          //     return !val.length ? '' : f;
          //   }
          // }}
          // parser={(value) => (value.replace(',', '').replace('.', ''))} */}
      </Form.Item>
    ),
  }, {
    label: 'En (mm)',
    span: 2,
    children: offer?.en,
  }, {
    label: 'Boy (mm)',
    span: 2,
    children: offer?.boy,
  }, {
    label: 'Malzeme Kodu',
    span: 2,
    children: (
      <Form.Item name="stock_material_code">
        <Select
          disabled={!canChangeStock}
          placeholder="Seçiniz"
          options={stockMaterialCodes}
          onChange={() => {
            setShowStockUpdate(true);
            form.resetFields(['stock_en', 'stock_consumed_size']);
          }}
        />
      </Form.Item>
    ),
  }, {
    label: 'Bobin En (mm)',
    span: 2,
    children: (
      <Form.Item name="stock_en">
        <Select
          disabled={!canChangeStock}
          placeholder="Seçiniz"
          options={stockMaterialEn}
          onChange={() => {
            setShowStockUpdate(true);
            form.resetFields(['stock_consumed_size']);
          }}
        />
      </Form.Item>
    ),
  }, {
    label: 'Bobin Boy (metre)',
    span: 2,
    children: (
      <Form.Item name="stock_boy">
        <InputNumber {...numberConf} precision="2" />
      </Form.Item>
    ),
  }, {
    label: 'Tüketilen Boy',
    span: 2,
    children: (
      stockEn ? (
        <div style={{ maxWidth: 300 }}>
          <Space direction="vertical">
            {stockDetail[stockMaterialCode][stockEn].map((bobin, index) => {
              const max = Math.max((bobin.boy - bobin.consumed_boy), 0);
              return (
                <Form.Item
                  name={['stock_consumed_size', index, stockDetail[stockMaterialCode][stockEn][index].id]}
                  rules={[{ ...required[0], required: canChangeStock }]}
                  key={index}
                >
                  <InputNumber
                    disabled={!canChangeStock}
                    {...numberConf}
                    max={max}
                    addonBefore={`Bobin ${index + 1} - Kalan Boy: ${max}`}
                    onChange={() => setShowStockUpdate(true)}
                  />
                </Form.Item>
              );
            })}
          </Space>
        </div>
      ) : (
        <Input disabled />
      )
    ),
  }], [{
    label: 'Renk',
    children: (
      <Form.Item name="color" rules={required}>
        <Checkbox.Group
          options={[{
            label: 'C M Y K', value: 'C M Y K',
          }, {
            label: 'W', value: 'W',
          }]}
        />
      </Form.Item>
    ),
  }, {
    label: 'P1',
    children: (
      <Form.Item name="p1">
        <Input />
      </Form.Item>
    ),
  }, {
    label: 'P2',
    children: (
      <Form.Item name="p2">
        <Input />
      </Form.Item>
    ),
  }, {
    label: 'Lak',
    span: 3,
    children: (
      <Form.Item
        name="lak"
        rules={required}
      >
        <Radio.Group
          name="lak_radio"
          onChange={({ target }) => {
            setShowOfferLakUpdate(target.value !== offer.lak);
          }}
        >
          <Radio value="Yok">Yok</Radio>
          <Radio value="Mat">Mat</Radio>
          <Radio value="Parlak">Parlak</Radio>
          <Radio value="Ribon">Ribon</Radio>
        </Radio.Group>
      </Form.Item>
    ),
  }, {
    label: 'Selofan',
    span: 3,
    children: (
      <Form.Item
        name="selofan"
        rules={required}
      >
        <Radio.Group
          name="selofan_radio"
          onChange={({ target }) => {
            setShowOfferSelofanUpdate(target.value !== offer.selofan);
          }}
        >
          <Radio value="Yok">Yok</Radio>
          <Radio value="Mat">Mat</Radio>
          <Radio value="Soft Touch">Soft Touch</Radio>
        </Radio.Group>
      </Form.Item>
    ),
  }, {
    label: 'Diğer',
    span: 3,
    children: (
      <Form.Item name="others">
        <Checkbox.Group
          options={[{
            label: 'Soğuk Yaldız', value: 'Soğuk Yaldız',
          }, {
            label: 'Sıcak Yaldız', value: 'Sıcak Yaldız',
          }, {
            label: 'Emboss', value: 'Emboss',
          }]}
        />
      </Form.Item>
    ),
  }], [{
    label: 'Yapıştırma',
    span: 2,
    children: (
      <Form.Item name="bonding_type" rules={required}>
        <Radio.Group name="bonding_type_radio">
          <Radio value="Makine">Makine</Radio>
          <Radio value="El">El</Radio>
        </Radio.Group>
      </Form.Item>
    ),
  }, {
    label: 'Kuka',
    span: 2,
    children: (
      <Form.Item name="kuka" rules={required}>
        <Radio.Group name="kuka_radio">
          <Radio value="25">25</Radio>
          <Radio value="40">40</Radio>
          <Radio value="50">50</Radio>
          <Radio value="76">76</Radio>
        </Radio.Group>
      </Form.Item>
    ),
  }, {
    label: 'Rulo Yönü',
    children: (
      <Form.Item name="roll_direction" rules={[{ ...required[0], required: isBondingTypeRequired }]}>
        <Radio.Group name="roll_direction_radio" className="radio-with-border">
          <Radio value={1}>
            <p className="t-center mt-1r mb-0">1</p>
            <Image width={80} preview={false} src="/images/YON01.png" />
          </Radio>
          <Radio value={2}>
            <p className="t-center mt-1r mb-0">2</p>
            <Image width={80} preview={false} src="/images/YON02.png" />
          </Radio>
          <Radio value={3}>
            <p className="t-center mt-1r mb-0">3</p>
            <Image width={80} preview={false} src="/images/YON03.png" />
          </Radio>
          <Radio value={4}>
            <p className="t-center mt-1r mb-0">4</p>
            <Image width={80} preview={false} src="/images/YON04.png" />
          </Radio>
          <Radio value={5}>
            <p className="t-center mt-1r mb-0">5</p>
            <Image width={80} preview={false} src="/images/YON05.png" />
          </Radio>
          <Radio value={6}>
            <p className="t-center mt-1r mb-0">6</p>
            <Image width={80} preview={false} src="/images/YON06.png" />
          </Radio>
          <Radio value={7}>
            <p className="t-center mt-1r mb-0">7</p>
            <Image width={80} preview={false} src="/images/YON07.png" />
          </Radio>
          <Radio value={8}>
            <p className="t-center mt-1r mb-0">8</p>
            <Image width={80} preview={false} src="/images/YON08.png" />
          </Radio>
        </Radio.Group>
      </Form.Item>
    ),
  }], [[{
    label: 'Vorey Kesim',
    span: 3,
    children: (
      <Form.Item name="vorey_bicak" rules={[{ ...required[0], required: isBicakRequired }]}>
        <Radio.Group
          name="vorey_bicak_radio"
          onChange={({ target }) => {
            if (target.value === 'Var') setBicak('vorey');
          }}
        >
          <Radio value="Var">Var</Radio>
          <Radio value="Yok">Yok</Radio>
        </Radio.Group>
      </Form.Item>
    ),
  }], [{
    label: 'Bıçak Numarası',
    span: 3,
    children: (
      <Form.Item name="bicak_no">
        <Input />
      </Form.Item>
    ),
  }], [{
    label: 'Tahta Bıçak',
    span: 3,
    children: (
      <Form.Item name="tahta_bicak" rules={[{ ...required[0], required: isBicakRequired }]}>
        <Radio.Group
          name="tahta_bicak_radio"
          onChange={({ target }) => {
            if (target.value === 'Var') setBicak('tahta');
          }}
        >
          <Radio value="Var">Var</Radio>
          <Radio value="Yok">Yok</Radio>
        </Radio.Group>
      </Form.Item>
    ),
  }, {
    label: 'Yan Yana',
    span: 3,
    children: (
      <Form.Item name="tahta_bicak_yanyana">
        <InputNumber {...numberConf} />
      </Form.Item>
    ),
  }, {
    label: 'Ara Boşluk',
    span: 3,
    children: (
      <Form.Item name="tahta_bicak_arabosluk">
        <InputNumber {...numberConf} precision="2" />
      </Form.Item>
    ),
  }], [{
    label: 'Yaprak Bıçak',
    span: 3,
    children: (
      <Form.Item name="yaprak_bicak" rules={[{ ...required[0], required: isBicakRequired }]}>
        <Radio.Group
          name="yaprak_bicak_radio"
          onChange={({ target }) => {
            if (target.value === 'Var') setBicak('yaprak');
          }}
        >
          <Radio value="Var">Var</Radio>
          <Radio value="Yok">Yok</Radio>
        </Radio.Group>
      </Form.Item>
    ),
  }, {
    label: 'Yan Yana',
    span: 3,
    children: (
      <Form.Item name="yaprak_bicak_yanyana">
        <InputNumber {...numberConf} />
      </Form.Item>
    ),
  }, {
    label: 'Ara Boşluk',
    span: 3,
    children: (
      <Form.Item name="yaprak_bicak_arabosluk">
        <InputNumber {...numberConf} precision="2" />
      </Form.Item>
    ),
  }, {
    label: 'Zet',
    span: 3,
    children: (
      <Form.Item name="yaprak_bicak_zet">
        <InputNumber {...numberConf} />
      </Form.Item>
    ),
  }], [{
    label: 'Flekso/Klişe',
    span: 3,
    children: (
      <Form.Item name="flekso_klise" rules={[{ ...required[0], required: isPrintTypeFlekso }]}>
        <Radio.Group name="flekso_klise_radio" disabled={!isPrintTypeFlekso}>
          <Radio value="Var">Var</Radio>
          <Radio value="Yok">Yok</Radio>
        </Radio.Group>
      </Form.Item>
    ),
  }, {
    label: 'Yan Yana',
    span: 3,
    children: (
      <Form.Item name="flekso_klise_yanyana">
        <InputNumber disabled={!isPrintTypeFlekso} />
      </Form.Item>
    ),
  }, {
    label: 'Ara Boşluk',
    span: 3,
    children: (
      <Form.Item name="flekso_klise_arabosluk">
        <InputNumber {...numberConf} precision="2" disabled={!isPrintTypeFlekso} />
      </Form.Item>
    ),
  }, {
    label: 'Zet',
    span: 3,
    children: (
      <Form.Item name="flekso_klise_zet">
        <InputNumber disabled={!isPrintTypeFlekso} />
      </Form.Item>
    ),
  }]], [{
    label: 'Sarım Çapı',
    span: 2,
    children: (
      <Form.Item name="sarim_capi">
        <InputNumber {...numberConf} />
      </Form.Item>
    ),
  }, {
    label: 'Sarım Adedi',
    span: 2,
    children: (
      <Form.Item name="sarim_adedi">
        <InputNumber {...numberConf} />
      </Form.Item>
    ),
  }, {
    label: 'Toplam Etiket Adedi',
    span: 2,
    children: (
      <Form.Item name="toplam_etiket_adedi">
        <InputNumber
          {...numberConf}
          formatter={(value, info) => {
            if (!info.userTyping) {
              let val = info.input === 'undefined' || info.input === '' ? value : info.input;
              val = val.replaceAll(',', '').replaceAll('.', '');
              const f = formatK(Number.parseInt(val, 10), 0);
              return !val.length ? '' : f;
            }
          }}
          parser={(value) => (value.replace(',', '').replace('.', ''))}
        />
      </Form.Item>
    ),
  }, {
    label: 'Sevkiyat Tarihi',
    span: 2,
    children: (
      <Form.Item name="shipment_date">
        <DatePicker placeholder="Tarih Seçiniz" {...dateConf} />
      </Form.Item>
    ),
  }], [{
    label: 'Teklif Notları',
    span: 3,
    children: (
      <div style={{ position: 'relative' }}>
        {offerNotes?.length ? offerNotes.split('\n').map((line, index) => <p className="mb-0" key={index}>{line}</p>) : '--'}
        <Form.Item name="offer_notes" className="hidden">
          <TextArea autoSize={{ minRows: 4, maxRows: 6 }} />
        </Form.Item>
      </div>
    ),
  }, {
    label: 'İş Emri Notları',
    span: 3,
    children: (
      <Form.Item name="notes">
        <TextArea autoSize={{ minRows: 4, maxRows: 6 }} />
      </Form.Item>
    ),
  }]];

  function onSuccess(description = '') {
    notificationApi.success({
      message: 'İşlem Başarılı',
      description,
    });
  }

  function onError(description = '') {
    notificationApi.error({
      message: 'Hata',
      description,
    });

    setSaveLoading(false);
  }

  function skipOfferBase() {
    setShowOfferLakUpdate(false);
    setShowOfferSelofanUpdate(false);
    setTimeout(() => form.submit(), 500);
  }

  async function onSubmit(values) {
    setSaveLoading(true);

    const order = {
      ...values,
      status: statusCurrent,
      offer,
    };

    Object.entries(order).filter((v) => (v[1] === '')).forEach((item) => {
      order[item[0]] = null;
    });

    Object.keys(order).filter((v) => v.includes('_date')).forEach((item) => {
      if (order[item]) {
        order[item] = order[item].unix();
      }
    });

    if (!isPrintTypeFlekso) {
      order.flekso_klise = null;
      order.flekso_klise_yanyana = null;
      order.flekso_klise_arabosluk = null;
      order.flekso_klise_zet = null;
    }

    if (!(isGrafik || isAdmin)) {
      order.print_approval_date = undefined;
    }

    if (!(isUretim || isAdmin)) {
      const fields = ['stock_consumed_size', 'production_start_date', 'toplam_etiket_adedi', 'shipment_date'];

      Object.keys(order).forEach((key) => {
        if (fields.includes(key)) order[key] = undefined;
      });
    }

    ['stock_material_code', 'stock_en', 'stock_consumed_size'].forEach((k) => {
      // Clear if the input resetted.
      if (!order[k]) {
        order[k] = null;
      }
    });

    if (orderId) {
      order.offer_notes = undefined;
    }

    if (showOfferLakUpdate || showOfferSelofanUpdate) {
      const title = showOfferLakUpdate && showOfferSelofanUpdate
        ? 'Lak ve selofan değerlerini'
        : showOfferLakUpdate
          ? 'Lak değerini'
          : 'Selofan değerini';

      const message = `İşleme teklifden farklı ${showOfferLakUpdate && showOfferSelofanUpdate ? 'değerler' : 'değer'} ile devam ederseniz mükerrer kayıt oluşacaktır.`;

      Modal.confirm({
        title: `${title} değiştirdiniz.`,
        content: (
          <div>
            <p>{message}</p>
          </div>
        ),
        closable: false,
        okText: 'Devam Et',
        onOk() {
          skipOfferBase();
        },
        cancelText: 'Vazgeç',
        onCancel() {
          setSaveLoading(false);
        },
      });

      return;
    }

    if (showStockUpdate && order.stock_consumed_size) {
      Modal.confirm({
        title: 'Stok Çıkış',
        content: (
          <div>
            <p>Stokları kayıtlarını etkileyecek bir değişiklik yaptınız.</p>
            <p>
              İşleme devam ederseniz stok çıkış işlemi yapılacaktır,&nbsp;
              <u>bu işlem geri alınamaz.</u>
            </p>
          </div>
        ),
        closable: false,
        okText: 'Devam Et',
        onOk() {
          makeStockOut(order);
        },
        cancelText: 'Vazgeç',
        onCancel() {
          setSaveLoading(false);
        },
      });

      return;
    }

    if (orderId) {
      await updateDoc(collections.ORDERS, orderId, order).then(() => {
        onSuccess('İş emri güncellendi.');
        setTimeout(() => {
          navigate(routes.ORDERS);
        }, 500);
      }).finally(() => {
        setSaveLoading(false);
      });
    } else {
      await addDoc(collections.ORDERS, order).then(() => {
        onSuccess('İş emri oluşturuldu.');
        setTimeout(() => {
          navigate(routes.ORDERS);
        }, 500);
      }).finally(() => {
        setSaveLoading(false);
      });
    }
  }

  async function makeStockOut(order) {
    try {
      const requests = [];
      const bobins = Object.values(stockPaperFilter).find((sp) => Object
        .keys(sp).includes(order.offer.material))[order.offer.material][
        order.stock_material_code][order.stock_en];

      const afterFilter = order.stock_consumed_size
        .filter((s) => Object.values(s)[0])
        .map((s) => {
          const [id] = Object.keys(s);
          const [value] = Object.values(s);
          return {
            bobin: bobins.find((b) => b.id === id),
            usage: value,
          };
        });

      afterFilter.forEach(async ({ bobin, usage }) => {
        const next = (bobin.consumed_boy + usage);

        if (!(bobin.boy - next >= 0)) {
          onError('Girilen miktarda stok kalmamıştır.');
          return;
        }

        requests.push(
          updateDoc(collections.STOCK_PAPER, bobin.id, {
            ...bobin,
            id: undefined,
            consumed_boy: next,
          }),
          addDoc(collections.STOCK_PAPER_OUT, {
            ...bobin,
            id: undefined,
            original_id: bobin.id,
            out: usage,
            next_consumed_boy: next,
            via: 'ORDER',
          }),
        );
      });

      await Promise.all(requests).then(() => {
        setShowStockUpdate(false);
        syncStockPaper();
        onSuccess('Kağıt stok çıkış işlemi gerçekleşti.');
        setTimeout(() => form.submit(), 100);
      });
    } catch (err) {
      onError('Şu an işleminizi gerçekleştiremiyoruz.');
    }
  }

  function handlePrint() {
    window.open(`/print/orders/${orderId}`, '', 'width=800,height=800');
  }

  useEffect(() => {
    if (!orderId && offer) {
      setRequired([]);
    }
  }, [orderId, offer]);

  if (!(isAdmin || isTemsilci || isUretim || isGrafik)) {
    return <Page404 />;
  }

  return (
    <Row>
      <Col lg={24} xl={20}>
        <Form
          form={form}
          initialValues={{
            status: statusCurrent,
          }}
          onFinish={onSubmit}
          onFinishFailed={() => window.scrollTo(0, 0)}
          autoComplete="off"
          layout="horizontal"
        >
          <Card
            title={orderId ? 'İş Emri Düzenle' : 'Yeni İş Emri Oluştur'}
            bordered={false}
            loading={isLoading}
            extra={(
              offer && (
                <Space>
                  <span>Grafiker:</span>
                  <Form.Item name="grapher" rules={required} className="w-200p mb-0">
                    <Select
                      placeholder="Seçiniz"
                      options={USERS.GRAFIK}
                    />
                  </Form.Item>
                </Space>
              )
            )}
          >
            {offer ? (
              <>
                <Form.Item name="status">
                  <Steps
                    current={statusCurrent}
                    items={ORDER_STATUS}
                    onChange={(value) => setStatusCurrent(value)}
                  />
                </Form.Item>
                <Descriptions className="mt-2r" size="small" bordered items={sections[0]} />
                <Form.Item name="print_type" rules={required}>
                  <Radio.Group className="w-100" name="print_type_radio">
                    <Descriptions className="mt-2r" size="small" bordered items={sections[1]} />
                  </Radio.Group>
                </Form.Item>
                <Descriptions className="mt-2r" size="small" bordered items={sections[3]} />
                <Descriptions className="mt-2r" size="small" bordered items={sections[4]} />
                <Descriptions className="mt-2r" size="small" bordered title="Etiket Akış Bilgileri" items={sections[5]} />
                <Radio.Group className="w-100" name="bicak_klise_radio">
                  <Row className="mt-2r" gutter={[32, 0]}>
                    <Col span={24} className="ant-descriptions">
                      <div className="ant-descriptions-header">
                        <div className="ant-descriptions-title">Bıçak ve Klişe Bilgileri</div>
                      </div>
                    </Col>
                    <Col span={6}>
                      {/* Vorey Kesim */}
                      <Descriptions size="small" bordered layout="vertical" items={sections[6][0]} />
                      <Descriptions size="small" bordered layout="vertical" items={sections[6][1]} className="mt-1r" />
                    </Col>
                    <Col span={6}>
                      {/* Tahta Bıçak */}
                      <Descriptions size="small" bordered layout="vertical" items={sections[6][2]} />
                    </Col>
                    <Col span={6}>
                      {/* Yaprak Bıçak */}
                      <Descriptions size="small" bordered layout="vertical" items={sections[6][3]} />
                    </Col>
                    <Col span={6}>
                      {/* Flekso/Klişe */}
                      <Descriptions size="small" bordered layout="vertical" items={sections[6][4]} />
                      {!isPrintTypeFlekso && (
                        <div className="disabled-overlay flekso" />
                      )}
                    </Col>
                  </Row>
                </Radio.Group>
                <Descriptions className="mt-2r" size="small" bordered title="Üretim Bilgileri" items={sections[7]} />
                <Descriptions className="mt-2r" size="small" bordered title="Notlar" items={sections[8]} />
                <Col
                  style={{
                    marginTop: '2rem',
                    textAlign: 'right',
                  }}
                >
                  <Space>
                    {orderId && (
                      <Button
                        size="large"
                        icon={React.createElement(PrinterFilled)}
                        onClick={handlePrint}
                      >
                        Yazdır
                      </Button>
                    )}
                    <Button
                      type="primary"
                      size="large"
                      danger
                      icon={React.createElement(CloseOutlined)}
                      onClick={() => { navigate(routes.ORDERS); }}
                    >
                      İptal
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      icon={React.createElement(SaveOutlined)}
                      loading={saveLoading}
                    >
                      Kaydet
                    </Button>
                  </Space>
                </Col>
              </>
            ) : (
              <p>Teklif bulunamadı. Lütfen teklif ekranından geliniz.</p>
            )}
          </Card>
        </Form>
      </Col>
      {contextHolder}
    </Row>
  );
}
